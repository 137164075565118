/* eslint-disable no-restricted-globals */
// serviceWorker.js

import { precacheAndRoute } from 'workbox-precaching'

// Set the precache manifest generated by Workbox during build
// Ensure that 'self.__WB_MANIFEST' is correctly set by Workbox during the build.
// If 'self.__WB_MANIFEST' is not available, use an empty array to prevent the "not iterable" error.
precacheAndRoute(self.__WB_MANIFEST || [])

self.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open('NAVIOCACHES').then((cache) => {
      // Cache additional assets during installation if needed.
      return cache.addAll(['/index.html', '/static/js/bundle.js', '/static/css/main.css'])
    }),
  )
})

self.addEventListener('fetch', (event) => {
  event.respondWith(
    caches.match(event.request).then((response) => {
      return response || fetch(event.request)
    }),
  )
})

self.addEventListener('activate', (event) => {
  event.waitUntil(
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames.map((cacheName) => {
          if (cacheName !== 'NAVIOCACHES') {
            return caches.delete(cacheName)
          }
        }),
      )
    }),
  )
})

// Export the register function for use in your index.js (or index.jsx)
export function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
      })
      .catch((error) => {
        console.error('Error registering Service Worker:', error)
      })
  }
}
