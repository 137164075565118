import secureLocalStorage from 'react-secure-storage'

export async function getRefreshToken() {
  const myToken = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_REFRESH_TOKEN')
  return myToken
}

export async function setRefreshToken(p_refreshToken) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_REFRESH_TOKEN', p_refreshToken)
}

export async function signOutUser() {
  await /*localStorage*/ secureLocalStorage.removeItem('RX_AUTO_USER')
  await /*localStorage*/ secureLocalStorage.removeItem('RX_AUTO_REFRESH_TOKEN')
}

export function clearLocalStorage() {
  /*localStorage*/ secureLocalStorage.clear()
}

export async function setUser(p_user) {
  if (p_user) {
    await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_USER', JSON.stringify(p_user))
  }
}

export async function getUser() {
  const data = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_USER')
  return JSON.parse(data)
}

export async function setUserLanguage(p_lang) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_LANG', JSON.stringify(p_lang))
}

export async function getUserLang() {
  const data = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_LANG')
  return JSON.parse(data)
}

export async function setUserLabels(p_labels) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_USER_LABELS', JSON.stringify(p_labels))
}

export async function getUserLabels() {
  const data = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_USER_LABELS')
  return JSON.parse(data)
}

export async function setCountryInfo(p_info) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_COUNTRY_INFO', JSON.stringify(p_info))
}

export async function getCountryInfo() {
  const data = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_COUNTRY_INFO')
  return JSON.parse(data)
}

export async function setCountry(p_info) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_COUNTRY', JSON.stringify(p_info))
}

export async function getCountry() {
  const data = await /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_COUNTRY')
  return JSON.parse(data)
}

export async function setTheme(p_info) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_THEME', p_info)
}

export function getTheme() {
  const data = /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_THEME')
  return data
}

export async function setActifCountry(p_info) {
  await /*localStorage*/ secureLocalStorage.setItem('RX_AUTO_ACTIVE_COUNTRY', p_info)
}

export function getActifCountry() {
  const data = /*localStorage*/ secureLocalStorage.getItem('RX_AUTO_ACTIVE_COUNTRY')
  return data
}
